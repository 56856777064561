import logo from "./logo.svg";
import "./App.css";
import Header from "./Component/Header";
import { Children } from "react";
import { BrowserRouter, Route, a, Routes, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./Component/Footer";
import Course from "./pages/Course";
import News from './pages/News'
import InfoList from "./Component/values/valueList";
import CharmItem from "./Component/charm/charmList";
import Intro from "./Component/intro/intro"
import Advs from "./Component/advantages/advs"
import Admission from "./Component/admission/admi"
import IntroDuctImg from "./Component/introduct-img/introduct_img";
import Newss from "./Component/news/news"

function App() {
  const Layout = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Layout />}>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/courses" exact element={<Course />} />
          <Route path="/news" exact element={<News />} />
          {/* <Route path="/admin" exact element={<AdminD />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
