import React from "react";
import "./introduct_img.css";
// import img from "./headimg.png"
import img from "./mmm.png"
const IntroDuctImg = () => (
  <>
    <div className="container">
        <div class="head-introduct-container">
        <div class="quote-container">
            <div class="quote">
                <h2 className="quote-h2">ЗӨВ ТӨЛӨВШИЛ<br/>
                ӨНДӨР БҮТЭЭМЖ</h2>
                <p>Мэдлэг чадвартай дэлхийд танигдах мэргэжилтэнүүдийг бүтээнэ. </p>
            </div>
        </div>
    
        <div class="introduct-img-container">
            <img class="introduct-img" src={img} alt=""/>
        </div>
    </div>
    </div>
    
  </>
);

export default IntroDuctImg;
