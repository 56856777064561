import React, {useEffect, useState} from "react";
import { IonIcon } from "@ionic/react";
import { close, search, cart, arrowForward, menu } from "ionicons/icons";
import heroBgImage from "../assets/images/hero-bg.svg";
import heroBanner1 from "../assets/images/hero-banner-1.jpg";
import heroBanner2 from "../assets/images/hero-banner-2.jpg";
import heroShape1 from "../assets/images/hero-shape-1.svg";
import heroShape2 from "../assets/images/hero-shape-2.png";
import category1Icon from "../assets/images/category-1.svg";
import category2Icon from "../assets/images/category-2.svg";
import category3Icon from "../assets/images/category-3.svg";
import category4Icon from "../assets/images/category-4.svg";
import IntroDuctImg from "../Component/introduct-img/introduct_img";
import img from "../Component/introduct-img/headimg.png"
import Values from '../Component/values/valueList'
import Intro from '../Component/intro/intro'
import Countries from '../Component/country_slider/countries'
import HeroComponent from "../Component/HeroComponent";
export default function Home() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* <div
        className="section hero has-bg-image"
        id="home"
        aria-label="home"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="container"></div>
      </div> */}
       {windowWidth <= 500 ? <HeroComponent /> :  <IntroDuctImg />}
  

      {/* <div className="container">
        <p className="section-subtitle">Categories</p>

        <h2 className="h2 section-title">
          Online <span className="span">classNamees</span> For Remote Learning.
        </h2>

        <p className="section-text">
          Consectetur adipiscing elit sed do eiusmod tempor.
        </p>

        <ul className="grid-list">
          <li>
            <div className="category-card" style={{ color: "170, 75%, 41%" }}>
              <div className="card-icon">
                <img
                  src={category1Icon}
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Online Degree Programs"
                  className="img"
                />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Online Degree Programs
                </a>
              </h3>

              <p className="card-text">
                Lorem ipsum dolor consec tur elit adicing sed umod tempor.
              </p>

              <span className="card-badge">7 Courses</span>
            </div>
          </li>

          <li>
            <div className="category-card" style={{ color: "351, 83%, 61%" }}>
              <div className="card-icon">
                <img
                  src={category2Icon}
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Non-Degree Programs"
                  className="img"
                />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Non-Degree Programs
                </a>
              </h3>

              <p className="card-text">
                Lorem ipsum dolor consec tur elit adicing sed umod tempor.
              </p>

              <span className="card-badge">4 Courses</span>
            </div>
          </li>

          <li>
            <div className="category-card" style={{ color: "229, 75%, 58%" }}>
              <div className="card-icon">
                <img
                  src={category3Icon}
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Off-Campus Programs"
                  className="img"
                />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Off-Campus Programs
                </a>
              </h3>

              <p className="card-text">
                Lorem ipsum dolor consec tur elit adicing sed umod tempor.
              </p>

              <span className="card-badge">8 Courses</span>
            </div>
          </li>

          <li>
            <div className="category-card " style={{ color: "42, 94%, 55%" }}>
              <div className="card-icon">
                <img
                  src={category4Icon}
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Hybrid Distance Programs"
                  className="img"
                />
              </div>

              <h3 className="h3">
                <a href="#" className="card-title">
                  Hybrid Distance Programs
                </a>
              </h3>

              <p className="card-text">
                Lorem ipsum dolor consec tur elit adicing sed umod tempor.
              </p>

              <span className="card-badge">8 Courses</span>
            </div>
          </li>
        </ul>
      </div> */}
    <Values/>
    <Intro/>
    <Countries></Countries>
    </>
  );
}
