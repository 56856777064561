import React from 'react';

import './advs.css';
import hundred from '../advantages/imgs/100green.png'
import hundredpink from '../advantages/imgs/100pink.png'
import thundred from '../advantages/imgs/200k.png'
import certf from '../advantages/imgs/certf.png'
import drive from '../advantages/imgs/drive.png'
import edu from '../advantages/imgs/edu.png'
import loc from '../advantages/imgs/loc.png'
import scholar from '../advantages/imgs/scholar.png'

import Title from '../title/Title'
const ValueItem = ({ img, infoValue }) => (
    <div class="card">
         <img src={img} alt="Placeholder Alt Text" />
        <p>{infoValue}</p>
    </div>
);


class AdvList extends React.Component{

    state = {
        allInfo: [
            {
                img: hundredpink,
                infoValue: 'СУРГАЛТЫН ТӨЛБӨР ҮНЭГҮЙ',
            },
            {
                img: hundred,
                infoValue: 'ДОТУУР БАЙР, ХООЛ ҮНЭГҮЙ',
                
            },
            {
                img: thundred,
                infoValue: 'САР БҮР 200’000 ТӨГРӨГНИЙ ТЭТГЭЛЭГТЭЙ',
            },
            {
                img: certf,
                infoValue: 'БҮРЭН ДУНД БОЛОВСРОЛ ЭЗЭМШИНЭ',
            },
            {
                img: drive,
                infoValue: 'А, М, В ЖОЛООНЫ АНГИЛАЛД 1 ЖИЛИЙН ОЮУТАН 30%, 2 ЖИЛИЙН ОЮУТАН 50% ХӨНГӨЛӨЛТТЭЙ СУРАЛЦАНА',
            },
            {
                img:edu,
                infoValue: 'ДОТООДЫН ИХ СУРГУУЛИУДАД ШАТЛАН СУРАЛЦАХ БОЛОМЖТОЙ',
            },
            {
                img: scholar,
                infoValue: 'ЗАСГИЙН ГАЗРЫН ТЭТГЭЛЭГЭЭР ОХУ, БНХАУ-ЫН ИХ ДЭЭД СУРГУУЛИУДАД СУРАЛЦУУЛНА'
            },
            {
                img: loc,
                infoValue: 'МЯНГАНЫ ЗАМ ДАГУУ БАЙРЛАЛТАЙ'
            }
        ]
    }

    render(){
        return(
            <>
                <div className="container">
                   <div className='adv-container'>
                    <Title style={{marginTop:"5rem", marginBottom: "5rem"}}>ДАВУУ ТАЛУУД</Title>
                        <div className="advantages">
                            {
                                this.state.allInfo.map((item, index) => (
                                    <ValueItem key={index} {...item} />
                                ))
                            }
                        </div>
                   </div>

                    
                </div>
            </>
        );
    }
}

export default AdvList;