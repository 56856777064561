import React from "react";
import { IonIcon } from "@ionic/react";
import { close, search, cart, arrowForward, menu } from "ionicons/icons";
import heroBgImage from "../assets/images/hero-bg.svg";
import heroBanner1 from "../assets/images/hero-banner-1.jpg";
import heroBanner2 from "../assets/images/hero-banner-2.jpg";
import heroShape1 from "../assets/images/hero-shape-1.svg";
import heroShape2 from "../assets/images/hero-shape-2.png";
import category1Icon from "../assets/images/category-1.svg";
import category2Icon from "../assets/images/category-2.svg";
import category3Icon from "../assets/images/category-3.svg";
import category4Icon from "../assets/images/category-4.svg";
import Newss from "../Component/news/news";
import NewsHead from "../Component/newsHead/newsHead";
export default function News() {
  return (
    <>
    <div className="container fkmid">
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
      <h1>ТУН УДАХГҮЙ</h1>
    </div>
     {/* <NewsHead/> */}
      {/* <Newss/> */}
    </>
  );
}
