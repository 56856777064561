import React from "react";
import { IonIcon } from "@ionic/react";
import {
  close,
  search,
  cart,
  arrowForward,
  menu,
  checkmarkDone,
} from "ionicons/icons";

import aboutBanner from "../assets/images/about-banner.jpg";
import aboutShape1 from "../assets/images/about-shape-1.svg";
import aboutShape2 from "../assets/images/about-shape-2.svg";
import aboutShape3 from "../assets/images/about-shape-3.png";
import aboutShape4 from "../assets/images/about-shape-4.svg";
import AdvList from "../Component/advantages/advs";
import Intro from "../Component/intro/intro";
import Admission from "../Component/admission/admi";
import ProgramList from "../Component/programs/programListt";
export default function Course() {
  return (
    <>
      <section id="course" class="course-hero">
        <div class="container">
          <div class="about-hero-txt banner-title">
            <h2> СУРГАЛТ</h2>
          </div>
        </div>
      </section>
      <ProgramList/>
      <AdvList/>

      <Admission/>
    </>
  );
}
