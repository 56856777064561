import React from "react";
import IntroDuctImg from "../Component/introduct-img/introduct_img";


export default function HeroComponent() {
    return( 
        <div id="about" class="about-heroC">
        <div class="container">
          <div class="about-hero-txt banner-title">
            <h2>Зөв төлөвшил<br></br> Өндөр бүтээмж</h2>
          </div>
        </div>
      </div>
)}