import React from "react";
import { IonIcon } from "@ionic/react";
import {
  close,
  search,
  cart,
  arrowForward,
  menu,
  checkmarkDone,
} from "ionicons/icons";

import aboutBanner from "../assets/images/about-banner.jpg";
import aboutShape1 from "../assets/images/about-shape-1.svg";
import aboutShape2 from "../assets/images/about-shape-2.svg";
import aboutShape3 from "../assets/images/about-shape-3.png";
import aboutShape4 from "../assets/images/about-shape-4.svg";
import CharmList from "../Component/charm/charmList";
import InfoList from "../Component/values/valueList";
import Countries from "../Component/country_slider/countries";
export default function About() {
  return (
    <>
      <div id="about" class="about-hero">
        <div class="container">
          <div class="about-hero-txt banner-title">
            <h2>БИДНИЙ ТУХАЙ</h2>
          </div>
        </div>
      </div>
      <InfoList />
      <CharmList />
      <Countries />
    </>
  );
}
