import React from "react";
import { IonIcon } from "@ionic/react";
import {
  close,
  search,
  cart,
  arrowForward,
  menu,
  checkmarkDone,
  logoYoutube,
  logoInstagram,
  logoLinkedin,
  logoFacebook,
  logoTwitter,
} from "ionicons/icons";
import backgroundImgFooter from "../assets/images/footer-bg.png";
import logo from "../assets/images/logo-light.svg";

import logo3 from "../assets/images/mobile-logo.png";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">

        <div className="footer-top section">
          <div className="container grid-list">
            <div className="footer-brand">
              {/* <a href="/" className="logo">
                <img src={logo3} width="162" height="50" alt="EduWeb logo" />
              </a> */}

              <p className="footer-brand-text">
                Мэдлэг чадвартай дэлхийд танигдах мэргэжилтэнүүдийг бүтээнэ.
              </p>

            </div>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Бидний тухай</p>
              </li>

              <li>
                <a href="" className="footer-a">
                  Зорилго
                </a>
              </li>
              <li>
                <a href="" className="footer-a">
                  Зорилт
                </a>
              </li>
              <li>
                <a href="" className="footer-a">
                  Алсын хараа 
                </a>
              </li>

              <li>
                <a href="#" className="footer-a">
                  Бидний бахархал
                </a>
              </li>
              <li>
                <a href="#" className="footer-a">
                  Гадаад харилцаа
                </a>
              </li>

            </ul>
            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Сургалт</p>
              </li>

              <li>
                <a href="#" className="footer-a">
                  Хөтөлбөрүүд
                </a>
              </li>

              <li>
                <a href="#" className="footer-a">
                  Давуу талууд
                </a>
              </li>

              <li>
                <a href="#" className="footer-a">
                  Элсэлт
                </a>
              </li>

            </ul>

            <div className="footer-list">
              <p className="footer-list-title">Бидэнтэй холбогдох:</p>

              <div className="wrapper">
                <span className="span">Хаяг: </span>
                <a href="https://maps.app.goo.gl/XmV41wtX1dSp3giJ6" className="footer-a">
                Төв аймаг, Эрдэнэ сум Тамганы 5-р баг
                </a>
              </div>

              <div className="wrapper">
                <span className="span">Утас: </span>
                <a href="tel:+011234567890" className="footer-a">
                  7027 6711
                </a>
              </div>

              <div className="wrapper">
                <span className="span">Email: </span>
                <a href="mailto:info@eduweb.com" className="footer-a">
                  erdenemsut@yahoo.com
                </a>
              </div>
              {/* <form action="" className="newsletter-form">
                <input
                  type="email"
                  name="email_address"
                  placeholder="Your email"
                  required
                  className="input-field"
                />

                <button type="submit" className="btn has-before">
                  <span className="span">Subscribe</span>
                  <IonIcon icon={arrowForward} />
                </button>
              </form> */}

              <ul className="social-list">
                <li>
                  <a href="https://www.facebook.com/erdene.msut.edu" className="social-a">
                    <IonIcon icon={logoFacebook} />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-a">
                    <IonIcon icon={logoInstagram} />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-a">
                    <IonIcon icon={logoTwitter} />
                  </a>
                </li>

                <li>
                  <a href="#" className="social-a">
                    <IonIcon icon={logoYoutube} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <p className="copyright">
              Copyright 2024 All Rights Reserved by{" "}
              <a href="#" className="copyright-a">
                AideaVision LLC
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
