import React, { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import {
  close,
  search,
  cart,
  arrowForward,
  menu,
  logoFacebook,
  logoTwitter,
  logoInstagram,
  logoYoutube,
} from "ionicons/icons";
import logo from "../assets/images/MBS2.png";
import logo2 from "../assets/images/logo-header.png";
import logo3 from "../assets/images/mobile-logo.png";

export default function Header() {
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isInfoActive, setIsInfoActive] = useState(true);
  const toggleNavbar = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  const closeNavbar = () => {
    setIsNavbarActive(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsHeaderActive(true);
      } else {
        setIsHeaderActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`  header ${isHeaderActive ? "active" : ""}`}
      data-header
    >
      <div className={`  top border-bottom ${isHeaderActive ? "active" : ""}`}>
        <div className="top-container container">
          <div className="flex-container">
            <ul className="top-container-links horizontal-list">
              <li>
                <a href="/news" className="button-style">
                  Мэдээ
                </a>
              </li>
              <li>
                <a href="/courses" className="button-style">
                  Элсэлт
                </a>
              </li>
              <li>
                <a href="/news" className="button-style">
                  Шилэн Данс
                </a>
              </li>
              <li>
                <a href="/news" className="button-style">
                  Холбоо барих
                </a>
              </li>
            </ul>

            <ul className="top-container-links horizontal-list">
              <li>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonIcon icon={logoFacebook} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonIcon icon={logoTwitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonIcon icon={logoInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonIcon icon={logoYoutube} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="middle-navbar container">
        <div className="container" style={{ paddingRight: "0", paddingLeft: '0' }}>
          <a href="/" className="logoFluid">
            <img src={logo} alt="МБС logo" className="fluid" />
          </a>

          <nav
            className={`navbar ${isNavbarActive ? "active" : ""}`}
            data-navbar
          >
            <div className="wrapper">
              <a href="/" className="logo ">
                <img src={logo3} alt="EduWeb logo" className="fluid" />
              </a>

              <button
                className="nav-close-btn"
                aria-label="close menu"
                onClick={toggleNavbar}
              >
                <IonIcon icon={close} />
              </button>
            </div>

            <ul className="navbar-list" onClick={closeNavbar}>
              <li className="navbar-item">
                <a href="/" className="navbar-a " data-nav-a>
                  НҮҮР ХУУДАС
                </a>
              </li>

              <li className="navbar-item">
                <a href="about" className="navbar-a " data-nav-a>
                  БИДНИЙ ТУХАЙ
                </a>
              </li>

              <li className="navbar-item">
                <a href="courses" className="navbar-a" data-nav-a>
                  СУРГАЛТ
                </a>
              </li>

              <li className="navbar-item">
                <a href="news" className="navbar-a" data-nav-a>
                  МЭДЭЭ
                </a>
              </li>

            </ul>
          </nav>
        </div>

        <div className="header-actions">
          <button
            className="header-action-btn"
            aria-label="open menu"
            onClick={toggleNavbar}
          >
            <IonIcon icon={menu} />
          </button>
        </div>

        <div
          className={`overlay ${isNavbarActive ? "active" : ""}`}
          onClick={toggleNavbar}
          data-overlay
        ></div>
      </div>
    </header>
  );
}
