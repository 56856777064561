import React from "react";
import "./programList.css";
import img from './icon.png';
import Title from '../title/Title'
import img2 from './icon2.png';
import img3 from './icon3.png';
const ProgramList = () => (
  <>
    <div className="container">
        <div className="program-container">
            <Title>СУРГАЛТУУД</Title>
            <div class="head-container">
                <div class="category">
                    <img src={img} alt=""/>
                    <h3 className="cat-title">МЭРГЭЖЛИЙН СУРГАЛТ</h3>
                    <div  className="year">
                        <p>1 жил</p>
                    </div>
                </div>
                <div class="cat-detail-container">
                    <div class="cat-details">
                        <p class="detail-p">ХАА-н машин механизмын ашиглалт, засварчин</p>
                        <p class="detail-p">Хүнд машин механизмын операторч</p>
                        <p class="detail-p">Гагнуурчин</p>
                        <p class="detail-p">Сүү боловсруулах үйлдвэрлэлийн ажилтан</p> 
                        <p class="detail-p">Оёмол бүтээгдэхүүний оёдолчин</p> 
                        <p class="detail-p">Тогооч</p> 
                        <p class="detail-p">Мах боловсруулах үйлдвэрлэлийн ажилтан</p> 
                        <p class="detail-p">Малчин</p> 
                        <p class="detail-p">Ойжуулагч</p> 
                    </div>
                </div>
            </div>





            <div class="head-container" style={{ backgroundColor: '#e6bbc2' }}>
                <div class="category">
                    <img src={img3} alt=""/>
                    <h3 className="cat-title">МЭРГЭЖЛИЙН БОЛОВСРОЛ ОЛГОХ СУРГАЛТ</h3>
                    <div  className="year" style={{ backgroundColor: '#e0a8b1' }}>
                        <p>2.5 жил</p>
                    </div>
                </div>
                <div class="cat-detail-container">
                    <div class="cat-details">
                    <p class="detail-p">ХАА-н машин механизмын ашиглалт, засварчин</p>
                    <p class="detail-p">Хүнд машин механизмын операторч</p>
                    <p class="detail-p">Гагнуурчин</p>
                    <p class="detail-p">Сүү боловсруулах үйлдвэрлэлийн ажилтан</p> 
                    <p class="detail-p">Оёмол бүтээгдэхүүний оёдолчин</p> 
                    <p class="detail-p">Тогооч</p> 
                    <p class="detail-p">Мах боловсруулах үйлдвэрлэлийн ажилтан</p> 
                    <p class="detail-p">Малчин</p> 
                    <p class="detail-p">Ойжуулагч</p> 
                    </div>
                </div>
            </div>




            <div class="head-container" style={{ backgroundColor: '#b0dbd4' }}>
                <div class="category">
                    <img src={img2} alt=""/>
                    <h3 className="cat-title">ТЕХНИКИЙН БОЛОВСРОЛ ОЛГОХ</h3>
                    <div  className="year" style={{ backgroundColor: '#7cd4c6' }}>
                        <p>3 жил</p>
                    </div>
                </div>
                <div class="cat-detail-container">
                    <div class="cat-details">
                    <p class="detail-p">Малын бага эмч</p>
                    <p class="detail-p">Зоо техникч</p>
                    </div>
                </div>
            </div>


        </div>



    
    </div>
    
  </>
);

export default ProgramList;
