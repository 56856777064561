import React from "react";
import "./admi.css";
import admform from './contract.png'
import Title from "../title/Title"
const Admission = () => (
  <>
    <div className="container">
        <div class="admission-container">
                <Title style={{marginBottom: "5rem", marginTop:"5rem"}} >ЭЛСЭЛТ</Title>
                <div class="admission-details">
                    <div class="admission-elements">
                        <h2>Бүрдүүлэх материалууд</h2>
                        <ul>
                            <li>2 хувь цээж зураг</li>
                            <li>Хувийн хэрэг</li>
                            <li>Иргэний үнэмлэх</li>
                            <li>Суурь боловсролын гэрчилгээ</li>
                        </ul>
                    </div>
                    <div class="admission-img">
                        <img src={admform} alt="contract"/>
                    </div>
                </div>
            </div>
    </div>
  </>
);

export default Admission;
