import React from "react";
import "./intro.css";

const Intro = () => (
  <>
    <div className="container">
        <div className="card-container">
            <div className="intro-card">
                <h2 className="card-h1">СУРГАЛТ</h2>
                <div className="intro-details">
                    <div className="intro-p text-fontSize ">
                        <p>
                            1 ЖИЛ<br />
                            2.5 ЖИЛ<br />
                            3 ЖИЛ
                        </p>
                    </div>
                    <ul className=" text-fontSize">
                        <li className="intro-li">ХАА-н машин механизмын ашиглалт, засварчин</li>
                        <li className="intro-li">Хүнд машин механизмын операторч</li>
                        <li className="intro-li">Гагнуурчин</li>
                        <li className="intro-li">Сүү боловсруулах үйлдвэрлэлийн ажилтан гэх мэт</li>
                    </ul>
                </div>
            </div>
            <div className="intro-card">
                <h2 className="card-h1">100%</h2>
                <div className="intro-second-details">
                        <p className="intro-sec-p text-fontSize">
                            СУРГАЛТЫН ТӨЛБӨР ҮНЭГҮЙ
                        </p>
                        <p className="intro-sec-p text-fontSize">
                            ДОТУУР БАЙР, ХООЛ ҮНЭГҮЙ
                        </p>
                        <p className="intro-sec-p text-fontSize">
                            САР БҮР 200’000 ТӨГРӨГНИЙ ТЭТГЭЛЭГТЭЙ
                        </p>
                </div>
            </div>
        </div>
    </div>
    
  </>
);

export default Intro;
