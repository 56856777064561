import React from "react";
import "./values.css";

const ValueItem = ({ infoTitle, infoValue }) => (
  <div class="valueItem">
    <h3 className="ValueH1">{infoTitle}</h3>
    <p className="ValueP">{infoValue}</p>
  </div>
);

class InfoList extends React.Component {
  state = {
    allInfo: [
      {
        infoTitle: "ЗОРИЛГО",
        infoValue:
          "Хөдөө аж ахуйн сургалтын шинэлэг орчныг бүрдүүлж, хөдөлмөрийн зах үээлийн эрэлт, ажил олгогчийн шаардлагад нийцсэн, зөв төлөвшилтэй чадварлаг ажилтан бэлтгэнэ.",
      },
      {
        infoTitle: "ЗОРИЛТ",
        infoValue:
          "Хөдөө аж ахуйн сургалтын шинэлэг орчныг бүрдүүлж, хөдөлмөрийн зах үээлийн эрэлт, ажил олгогчийн шаардлагад нийцсэн, зөв төлөвшилтэй чадварлаг ажилтан бэлтгэнэ.",
      },
      {
        infoTitle: "АЛСЫН ХАРАА",
        infoValue:
          "Хөдөө аж ахуйн сургалтын шинэлэг орчныг бүрдүүлж, хөдөлмөрийн зах үээлийн эрэлт, ажил олгогчийн шаардлагад нийцсэн, зөв төлөвшилтэй чадварлаг ажилтан бэлтгэнэ.",
      },
    ],
  };

  render() {
    return (
      <>
        <div className="Values container">
          {this.state.allInfo.map((item, index) => (
            <ValueItem key={index} {...item} />
          ))}
        </div>
      </>
    );
  }
}

export default InfoList;
